<template>
  <div class="img-upload-wrapper" v-loading="loading">
    <el-upload
      v-if="imgUrl === ''"
      class="img-uploader"
      :action="onUploadUrl"
      :headers="headers"
      :show-file-list="false"
      :on-progress="handleUploadProgress"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :before-upload="beforeAvatarUpload"
    >
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div class="uploaded-img-preview" v-if="imgUrl !== ''">
      <el-image
        style="width: 100%;height: 100%"
        :src="imgUrl"
        title="点击查看大图"
        :preview-src-list="[imgUrl]"
      ></el-image>
      <i
        class="clear-img el-icon-delete"
        title="点击删除图片"
        @click="removePic"
      ></i>
    </div>
  </div>
</template>

<script>
import API_CONFIG from "@/request/api.config";
import { getToken } from "@/utils/auth";
export default {
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 2
    },
    urlKey:{
      type: String,
      default: "url"
    },
  },
  data() {
    return {
      loading: false,
      imgUrl: "",
      headers: {
        Authorization: getToken()
      }
    };
  },
  computed: {
    onUploadUrl() {
      return API_CONFIG.VGC_BASE_V4_API + "/oss/upload/cdn";
    }
  },
  watch: {
    value(val) {
      this.imgUrl = val;
    }
  },
  created() {
    this.imgUrl = this.value;
  },
  methods: {
    beforeAvatarUpload(file) {
      let allowType = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的图片格式不合法,格式需要为jpeg/jpg/png`);
        return false;
      }
      const isLtSize = file.size / 1024 / 1024 < this.size;
      if (!isLtSize) {
        this.$message.error(`上传图片大小不能超过 ${this.size}MB!`);
        return false;
      }
    },
    handleUploadProgress() {
      this.loading = true;
    },
    // file, fileList
    handleUploadSuccess(res) {
      this.loading = false;
      this.imgUrl = res;
      this.$emit("input", this.imgUrl);
      this.$emit("change", res);
    },
    // err, file, fileList
    handleUploadError() {
      this.loading = false;
      this.removePic();
    },
    removePic() {
      this.imgUrl = "";
      this.$emit("input", this.imgUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.img-upload-wrapper {
  box-sizing: border-box;
  width: 160px;
  height: 80px;
  background-color: #f1f1f1;
  border-radius: 4px;
  border: 1px dashed #ddd;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &:hover {
    background-color: #f8f8f8;
  }
  .img-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #989898;
    ::v-deep {
      .el-upload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
    }
  }
  .uploaded-img-preview {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: relative;
    .clear-img {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      transition: all 0.2s linear;
      cursor: pointer;
      background-color: #333;
      color: #fff;
      border-bottom-left-radius: 5px;
      visibility: hidden;
      &:hover {
        transform: scale(1.1);
      }
    }
    &:hover {
      .clear-img {
        visibility: visible;
      }
    }
  }
}
</style>