<template>
  <div class="component-wrap">
    <el-form ref="form" :model="from" label-position="top" label-width="auto">
      <el-form-item label="保存操作">
        <el-button type="primary" size="small" @click="onSave">保存</el-button>
      </el-form-item>
      <el-form-item label="富文本内容">
        <el-button icon="el-icon-edit-outline" size="small" @click="richTextDialog=true">编辑</el-button>
        <div class="rich-text-contnt" title="点击编写" v-html="from.content" @click="richTextDialog = true"></div>
      </el-form-item>

      <el-form-item label="标题">
        <el-input
          v-model="from.title"
          clearable
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="子标题">
        <el-input
          v-model="from.subTitle"
          clearable
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input
          v-model="from.description"
          clearable
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="次序">
      <el-input-number v-model="from.seq"></el-input-number>
    </el-form-item>
    <el-form-item label="页面图标">
      <el-input
        disabled
        v-model="from.icon"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item> -->
      <el-form-item label="显示/隐藏">
        <el-switch
          v-model="from.hidden"
          active-color="#ff4949"
          inactive-color="#13ce66"
          active-text="隐藏"
          inactive-text="显示"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <el-dialog
      title="编写富文本"
      :visible.sync="richTextDialog"
      append-to-body
      width="1000px"
    >
      <div style="padding: 10px">
        <Tinymce v-model="from.content" v-if="richTextDialog" :height="400" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import SingleImgUpload from "@/components/SingleImgUpload";
import Tinymce from "@/components/Tinymce";
export default {
  name: "ImgSildeWidgetSet",
  components: { Tinymce },
  props: {
    widgetData: {
      type: Object,
      default() {
        return {
          content: "",
          description: "",
          hidden: false,
          icon: "",
          imageUrl: "",
          images: [],
          redirectUrl: "",
          redirectWxMiniAppPath: "",
          seq: 0,
          subTitle: "",
          title: "",
          typeId: "",
          videoCoverUrl: "",
          videoUrl: "",
          videos: [],
        };
      },
    },
  },
  data() {
    return {
      from: {},
      richTextDialog: false,
    };
  },
  watch: {
    widgetData: {
      handler: function (newValue) {
        this.from = Object.assign({}, newValue);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSave() {
      this.$emit("onSave", this.from);
    },
  },
};
</script>

<style lang="scss" scoped>
.rich-text-contnt {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  height: 300px;
  border: 2px solid #ddd;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>