<template>
  <div class="video-upload-wrapper" v-loading="loading">
    <el-upload
      v-if="videoUrl === ''"
      class="video-uploader"
      :action="onUploadUrl"
      :headers="headers"
      accept=".mp4, .3gp, .m3u8, .avi"
      :show-file-list="false"
      :on-progress="handleUploadProgress"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :before-upload="beforeAvatarUpload"
    >
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div class="uploaded-video-preview" v-if="videoUrl !== ''">
      <video :src="videoUrl" class="video"></video>
      <i class="clear-video el-icon-delete" @click="clearUrl"></i>
    </div>
  </div>
</template>

<script>
import API_CONFIG from "@/request/api.config";
import { getToken } from "@/utils/auth";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      defaults: "",
    },
  },
  data() {
    return {
      loading: false,
      videoUrl: "",
      headers: {
        Authorization: getToken(),
      },
    };
  },
  computed: {
    onUploadUrl() {
      return API_CONFIG.VGC_BASE_V4_API + "/oss/upload/cdn";
    },
  },
  watch: {
    value(val) {
      this.videoUrl = val;
    },
  },
  created() {
    this.videoUrl = this.value;
  },
  methods: {
    beforeAvatarUpload(file) {
      console.log("file.type", file.type);
      let allowType = [
        "video/mp4",
        "application/x-mpegURL",
        "video/3gpp",
        "video/x-msvideo",
      ];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的视频格式不合法,格式需要为mp4/m3u8/3gp/avi`);
        return false;
      }
      const isLt200M = file.size / 1024 / 1024 < 200;
      if (!isLt200M) {
        this.$message.error("上传视频大小不能超过 200MB!");
        return false;
      }
    },
    handleUploadProgress() {
      this.loading = true;
    },
    handleUploadSuccess(res, file, fileList) {
      this.loading = false;
      this.videoUrl = res;
      this.$emit("change", this.videoUrl);
    },
    handleUploadError(err, file, fileList) {
      this.loading = false;
      this.clearUrl();
    },
    clearUrl() {
      this.videoUrl = "";
      this.$emit("change", this.videoUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.video-upload-wrapper {
  box-sizing: border-box;
  width: 100%;
  .video-uploader {
    width: 160px;
    height: 80px;
    background-color: #f1f1f1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #989898;
    border: 1px dashed #ddd;
    ::v-deep {
      .el-upload {
        width: 100%;
        height: 100%;
        line-height: 80px;
        overflow: hidden;
      }
    }
  }
  .uploaded-video-preview {
    width: 100%;
    height: 80px;
    border-radius: 4px;
    position: relative;
    .video {
      width: 100%;
      height: 100%;
    }
    .clear-video {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #f56c6c;
      cursor: pointer;
    }
  }
}
</style>