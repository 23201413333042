<template>
  <div class="custom-component-wrap">
    <el-select
      v-model="selectValue"
      :multiple="multiple"
      :collapse-tags="multiple"
      filterable
      remote
      clearable
      reserve-keyword
      :disabled="disabled"
      :placeholder="loading ? '数据请求中...' : placeholder"
      :remote-method="remoteMethod"
      :size="size"
      @change="onSelectChange"
      @blur="onSelectBlur"
      style="width: 100%"
    >
      <el-option
        v-for="item in currentOptions"
        :key="item.key"
        :label="customKeyName ? formatKeyNames(item, keyNames) : item[keyName]"
        :value="item[valueName]"
        :disabled="loading"
      >
      </el-option>
      <div class="select-page">
        <el-pagination
          :disabled="loading"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="optionNumber"
          :current-page="currentPage + 1"
          :total="totalSizes"
        >
        </el-pagination>
      </div>
      <p class="option-tips">
        <i class="el-icon-loading" v-show="loading"></i>
        {{ loading ? "加载中..." : "加载完成" }},
        <span class="text-nav" @click="handleRefresh">刷新</span>
        <br />
        每页显示{{ optionNumber }}条，共{{ totalPages }}页,
        <span class="text-nav" @click="handleRestOptions">重置</span>
        <br />
        可通过关键字搜索
      </p>
    </el-select>
  </div>
</template>

<script>
import { isEmprty } from "@/utils/validate";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Array, Number],
      default: () => {
        return "";
      },
    },
    filterKeyName: {
      type: Object,
      default: () => {
        return {};
      },
    },
    keyName: {
      type: [String],
      default: "name",
    },
    customKeyName: {
      type: Boolean,
      default: false,
    },
    keyNames: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    valueName: {
      type: [String],
      default: "id",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: { type: [String], default: "" },
    placeholder: {
      type: String,
      default: "点击选择或输入关键字搜索",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    optionNumber: {
      type: Number,
      default: 30,
    },
    asyncDataFun: {
      type: Function,
      default: () => {
        return () => {
          return new Promise((resolve) => {
            resolve({
              content: [],
              totalPages: 0,
              totalElements: 0,
              last: false,
            });
          });
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      selectValue: "",
      options: [],
      currentPage: 0,
      totalSizes: 0,
      totalPages: 0,
      isLast: false,
      _asyncDataFun: this.defaultFun(),
    };
  },
  computed: {
    currentOptions() {
      return this.options.map((target) => {
        return {
          ...target,
          key: this.createUniqueString() + target[this.valueName],
        };
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.initValue();
      },
      immediate: true,
      deep: true,
    },
    selectValue: {
      handler() {
        if (isEmprty(this.selectValue)) {
          this.getPageListOptions();
        }
      },
      immediate: true,
    },
    filterKeyName: {
      handler() {
        this.getPageListOptions();
      },
      deep: true,
      immediate: true,
    },
    // asyncDataFun: {
    //   handler() {
    //     if (typeof this.asyncDataFun == "function") {
    //       let _obj = { getOptionsData: null };
    //       _obj.getOptionsData = this.asyncDataFun;
    //       this._asyncDataFun = Object.assign({}, _obj).getOptionsData;
    //       console.log(this._asyncDataFun);
    //     } else {
    //       this._asyncDataFun = this.defaultFun();
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  methods: {
    defaultFun() {
      return new Promise((resolve) => {
        resolve({
          content: [],
          totalPages: 0,
          totalElements: 0,
          last: false,
        });
      });
    },
    initValue() {
      if (!isEmprty(this.value) && !this.multiple) {
        this.selectValue = this.value;
        this.currentPage = 0;
        this.setDefaultOptions();
        return false;
      }
      if (this.multiple && this.value instanceof Array) {
        this.selectValue = this.value.length > 0 ? this.value : [];
        this.currentPage = 0;
        this.setDefaultOptions();
        return false;
      }
      this.selectValue = this.value;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.currentPage = 0;
        this.getPageListOptions(query);
      } else {
        this.getPageListOptions();
      }
    },
    formatKeyNames(item, keyNames = []) {
      if (keyNames.length <= 0) {
        return this.keyName;
      } else {
        if (item) {
          let names = "";
          keyNames.forEach((key, index) => {
            if (Object.hasOwnProperty.call(item, key)) {
              names += `${item[key]}${
                index < keyNames.length - 1 ? " - " : ""
              }`;
            }
          });
          return names;
        } else {
          return this.keyName;
        }
      }
    },
    setDefaultOptions() {
      this.asyncDataFun({
        page: this.currentPage,
        size: this.optionNumber,
        [this.valueName]: this.value,
      }).then((res) => {
        let { content } = res.data;
        let optionIds = this.options.map((item) => item[this.valueName]);
        content.forEach((k, index) => {
          if (!optionIds.includes(k[this.valueName])) {
            this.options.unshift(k);
          }
        });
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getPageListOptions();
    },
    getPageListOptions(name = null) {
      this.loading = true;
      let params = {
        page: this.currentPage,
        size: this.optionNumber,
      };

      if (!isEmprty(name)) {
        params.page = this.currentPage;
        params[this.keyName] = name;
      }
      if (Object.keys(this.filterKeyName).length > 0) {
        for (const key in this.filterKeyName) {
          params[key] = this.filterKeyName[key];
        }
      }
      this.asyncDataFun(params)
        .then((res) => {
          let { content, totalPages, totalElements, last } = res.data;
          this.options = content;
          this.totalSizes = totalElements;
          this.totalPages = totalPages;
          this.isLast = last;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleRefresh() {
      this.getPageListOptions();
    },
    handleRestOptions() {
      this.currentPage = 0;
      this.getPageListOptions(null);
    },

    onSelectChange(val) {
      let _val = null;
      if (isEmprty(val) && !this.multiple) {
        _val = null;
      } else {
        _val = val;
      }
      this.$emit("input", _val);
      this.$emit("change", _val);
      let target = this.options.find((item) => item[this.valueName] == _val);
      this.$emit("check", { value: _val, target: target ? target : null });
    },
    onSelectBlur() {
      if (this.options.length <= 0) {
        this.currentPage = 0;
        this.getPageListOptions();
      }
    },
    createUniqueString() {
      const timestamp = +new Date() + "";
      const randomNum = parseInt((1 + Math.random()) * 65536) + "";
      return (+(randomNum + timestamp)).toString(32);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-component-wrap {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  overflow: hidden;
}
.option-tips {
  box-sizing: border-box;
  padding: 5px;
  font-size: 13px;
  color: #989898;
  line-height: 24px;
  text-align: center;
}
.select-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  i {
    flex: 1;
    padding: 10px;
    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }
  }
  span {
    flex: 2;
    padding: 10px;
  }
}
</style>