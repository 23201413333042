<template>
  <el-form ref="form" :model="form" label-position="top" label-width="auto">
    <el-form-item label="保存操作">
      <el-button type="primary" size="small" @click="onSave">保存</el-button>
    </el-form-item>
    <el-row :gutter="5" v-for="item in form.videos" :key="item.id">
      <el-col :span="12">
        <el-form-item label="视频"
          ><SingleVideoUpload v-model="item.url"
        /></el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="封面图"
          ><SingleImgUpload v-model="item.coverUrl"
        /></el-form-item>
      </el-col>
    </el-row>
    <el-button type="primary" size="small" @click="addVideo"
      >添加视频</el-button
    >
    <el-form-item label="标题">
      <el-input v-model="form.title" clearable placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="子标题">
      <el-input
        v-model="form.subTitle"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="描述">
      <el-input
        v-model="form.description"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item label="次序">
      <el-input-number v-model="widgetData.seq"></el-input-number>
    </el-form-item>
    <el-form-item label="页面图标">
      <el-input
        disabled
        v-model="widgetData.icon"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item> -->
    <el-form-item label="显示/隐藏">
      <el-switch
        v-model="form.hidden"
        active-color="#ff4949"
        inactive-color="#13ce66"
        active-text="隐藏"
        inactive-text="显示"
      >
      </el-switch>
    </el-form-item>
    
  </el-form>
</template>

<script>
import SingleImgUpload from "@/components/SingleImgUpload";
import SingleVideoUpload from "@/components/SingleVideoUpload";
export default {
  name: "ImgSildeWidgetSet",
  components: { SingleImgUpload, SingleVideoUpload },
  props: {
    widgetData: {
      type: Object,
      default() {
        return {
          content: "",
          description: "",
          hidden: false,
          icon: "",
          imageUrl: "",
          images: [],
          redirectUrl:"",
          redirectWxMiniAppPath:"",
          seq: 0,
          subTitle: "",
          title: "",
          typeId: "",
          videoCoverUrl: "",
          videoUrl: "",
          videos: [],
        };
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    widgetData: {
      handler: function (newValue) {
        this.from = Object.assign({},newValue) ;
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    onSave() {
      this.$emit("onSave", this.form);
    },
    createUniqueString() {
      const timestamp = +new Date() + "";
      const randomNum = parseInt((1 + Math.random()) * 65536) + "";
      return (+(randomNum + timestamp)).toString(32);
    },
    addVideo() {
      this.form.videos.push({
        id: this.createUniqueString(),
        coverUrl: "",
        description: "",
        hidden: false,
        icon: "",
        sort: 0,
        subTitle: "",
        title: "",
        url: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>