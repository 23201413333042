<template>
  <div class="tale-main-page">
    <PageHeader class="base-page-header">
      <template #right>
        <el-button
          class="mt-l"
          type="primary"
          :loading="submiting"
          @click="onSubmit"
          v-if="currentId == null"
          >保存</el-button
        >
        <el-button
          class="mt-l"
          type="warning"
          :loading="submiting"
          @click="onEdit"
          v-if="currentId !== null"
          >保存</el-button
        >
      </template>
    </PageHeader>
    <!-- <BasicPageHeader class="base-page-header"></BasicPageHeader> -->
    <div class="page-edit-wrapper" v-loading="loading">
      <div class="page-view-widget-wrap">
        <draggable
          v-model="widgetGroups"
          :options="{
            group: { name: 'widgetView', pull: 'clone' },
            seq: true,
          }"
          animation="300"
          class="page-view-widget-drag-list"
        >
          <transition-group>
            <div
              class="widget-item"
              v-for="item in widgetGroups"
              :key="item.id"
              :data-type="item.typeId"
            >
              <i
                class="widget-item-icon"
                :class="getWidgetIcon(item.typeId)"
              ></i>
              <span>{{ item.typeId | widgetName }}</span>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="page-view-wrapper">
        <div class="page-phone-wrapper">
          <el-scrollbar ref="pa" class="scroller" id="pa">
            <draggable
              class="dragArea page-list-view"
              :list="pageViews"
              group="widgetView"
              animation="300"
              @change="pageDraggable"
            >
              <div
                v-for="(item, index) in pageViews"
                :key="item.typeId + 'phone' + index"
                class="list-group-item"
                :class="{
                  disable: item.hidden,
                  active: index == currentIndex,
                }"
              >
                <div class="list-group-item-head">
                  <ImgWidetView v-if="item.typeId === 1" :url="item.imageUrl" />
                  <ImgSildeWidetView
                    :images="item.images"
                    v-if="item.typeId === 2"
                  />
                  <VideoWidgetView
                    v-if="item.typeId === 3"
                    :url="item.videoUrl"
                    :converUrl="item.videoCoverUrl"
                  />
                  <VideoSildeWidgetView
                    v-if="item.typeId === 4"
                    :videos="item.videos"
                  />
                  <FormWidgetView v-if="item.typeId === 5" />
                  <TextWidgetView
                    v-if="item.typeId === 6"
                    :text="item.content"
                  />
                  <RichTextWidgetView
                    v-if="item.typeId === 7"
                    :text="item.content"
                  />
                </div>
                <div class="list-group-item-control">
                  <span class="list-group-item-title">{{ item.title }}</span>
                  <span class="list-group-item-sequence">{{ index + 1 }}</span>
                  <i
                    class="el-icon-success list-group-item-icon"
                    style="color: #67c23a"
                    title="当前状态：已显示"
                    v-if="!item.hidden"
                  ></i>
                  <i
                    class="el-icon-warning-outline list-group-item-icon"
                    style="color: #f56c6c"
                    title="当前状态：已隐藏"
                    v-if="item.hidden"
                  ></i>

                  <i
                    class="el-icon-s-tools list-group-item-edit"
                    @click="onPageSectionSet(item, index)"
                  ></i>
                  <i
                    class="el-icon-delete-solid list-group-item-delete"
                    @click="removeFromPage(item, index)"
                  ></i>
                </div>
              </div>
              <!-- @click="addPageSection" -->
              <div class="page-phone-add">拖放组件到此处</div>
            </draggable>
          </el-scrollbar>
        </div>
      </div>
      <div class="page-widget-set-wrapper">
        <el-tabs v-model="activeName">
          <el-tab-pane label="版块设置" name="section">
            <ImgWidgetSet
              :widgetData="currentTarget"
              @onSave="onSaveSection"
              v-if="currentTarget.typeId === 1"
            />
            <ImgSildeWidgetSet
              :widgetData="currentTarget"
              @onSave="onSaveSection"
              v-if="currentTarget.typeId === 2"
            />
            <VideoWidgetSet
              :widgetData="currentTarget"
              @onSave="onSaveSection"
              v-if="currentTarget.typeId === 3"
            />
            <VideosWidgetSet
              :widgetData="currentTarget"
              @onSave="onSaveSection"
              v-if="currentTarget.typeId === 4"
            />
            <FormWidgetSet
              :widgetData="currentTarget"
              @onSave="onSaveSection"
              v-if="currentTarget.typeId === 5"
            />
            <TextWidgetSet
              :widgetData="currentTarget"
              @onSave="onSaveSection"
              v-if="currentTarget.typeId === 6"
            />
            <RichTextWidgetSet
              :widgetData="currentTarget"
              @onSave="onSaveSection"
              v-if="currentTarget.typeId === 7"
            />

            <!-- <widgetSet
              :target="currentTarget"
              v-if="currentTarget.id"
              @save="onSaveSection"
            /> -->
          </el-tab-pane>
          <el-tab-pane label="页面信息" name="page">
            <el-form ref="form" :model="pageInfo" label-width="80px">
              <el-form-item label="名称" required>
                <el-input
                  v-model="pageInfo.name"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="标题" required>
                <el-input
                  v-model="pageInfo.title"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="子标题">
                <el-input
                  v-model="pageInfo.subTitle"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="次序">
                <el-input-number v-model="pageInfo.seq"></el-input-number>
              </el-form-item>
              <el-form-item label="页面图标">
                <el-input
                  disabled
                  v-model="pageInfo.icon"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="显示/隐藏">
                <el-switch
                  v-model="pageInfo.hidden"
                  active-color="#ff4949"
                  inactive-color="#13ce66"
                  active-text="隐藏"
                  inactive-text="显示"
                >
                </el-switch>
              </el-form-item>
              <el-form-item label="上级页面">
                <BasicSelectByAsyncPage
                  v-model="pageInfo.parentId"
                  :asyncDataFun="GetPagesList"
                />
              </el-form-item>
              <el-form-item label="页面描述">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入"
                  v-model="pageInfo.description"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ImgWidetView from "./components/widgetViewItems/ImgWidetView";
import ImgSildeWidetView from "./components/widgetViewItems/ImgSildeWidetView";
import VideoWidgetView from "./components/widgetViewItems/VideoWidgetView";
import VideoSildeWidgetView from "./components/widgetViewItems/VideoSildeWidgetView";
import FormWidgetView from "./components/widgetViewItems/FormWidgetView";
import TextWidgetView from "./components/widgetViewItems/TextWidgetView";
import RichTextWidgetView from "./components/widgetViewItems/RichTextWidgetView";
import ImgWidgetSet from "./components/ImgWidgetSet";
import ImgSildeWidgetSet from "./components/ImgSildeWidgetSet";
import VideoWidgetSet from "./components/VideoWidgetSet";
import VideosWidgetSet from "./components/VideosWidgetSet";
import TextWidgetSet from "./components/TextWidgetSet";
import RichTextWidgetSet from "./components/RichTextWidgetSet";
import FormWidgetSet from "./components/FormWidgetSet";
import { isEmprty } from "@/utils/validate";
import { guid2 } from "@/utils/common";
import PageHeader from "@/components/PageHeader";
import BasicSelectByAsyncPage from "@/components/BasicSelectByAsyncPage";
import {
  GetPagesList,
  PostPages,
  PutPagesById,
  GetPagesById,
  GetPagesSectionsById,
} from "./api";
export default {
  name: "PageManagerEdit",
  components: {
    PageHeader,
    BasicSelectByAsyncPage,
    draggable,
    ImgWidetView,
    ImgSildeWidetView,
    VideoWidgetView,
    VideoSildeWidgetView,
    FormWidgetView,
    TextWidgetView,
    RichTextWidgetView,
    ImgWidgetSet,
    ImgSildeWidgetSet,
    VideoWidgetSet,
    VideosWidgetSet,
    TextWidgetSet,
    RichTextWidgetSet,
    FormWidgetSet,
  },
  data() {
    return {
      currentId: null,
      editModel: "add",
      submiting: false,
      loading: false,
      pageInfo: {
        description: "",
        hidden: false,
        icon: "",
        name: "",
        parentId: null,
        seq: null,
        subTitle: "",
        title: "",
      },
      widgetList: [],
      pageViews: [],
      pageName: "",
      currentIndex: null,
      currentTarget: {},
      activeName: "page",
      widgetGroups: [],
    };
  },
  filters: {
    widgetName(val) {
      switch (val) {
        case 1:
          return "图片";
        case 2:
          return "图片集合";
        case 3:
          return "视频";
        case 4:
          return "视频集合";
        case 5:
          return "表单";
        case 6:
          return "普通文本";
        case 7:
          return "富文本";

        default:
          return "图片";
      }
    },
  },
  created() {
    this.initWidgetGroups();
  },
  watch: {
    $route: {
      handler() {
        console.log("route", this.$route);
        if (this.$route.name == "PageManagerEdit") {
          if (this.$route.params.id) {
            this.getPageDetail(this.$route.params.id);
            this.editModel = "edit";
            this.currentId = this.$route.params.id;
          } else {
            this.editModel = "add";
            this.currentId = null;
            this.initForm();
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    GetPagesList,
    initForm() {
      this.pageViews = [];
      this.pageName = "";
      this.currentIndex = null;
      this.currentTarget = {};
      this.pageInfo = {
        description: "",
        hidden: false,
        icon: "",
        name: "",
        parentId: null,
        seq: null,
        subTitle: "",
        title: "",
      };
    },
    initWidgetGroups() {
      let widgets = [
        { id: 1, name: "IMAGE", desc: "图片" },
        { id: 2, name: "IMAGES", desc: "图片集" },
        { id: 3, name: "VIDEO", desc: "视频" },
        { id: 4, name: "VIDEOS", desc: "视频集" },
        { id: 5, name: "FORM", desc: "表单" },
        { id: 6, name: "TEXT", desc: "纯文本" },
        { id: 7, name: "RICH_TEXT", desc: "富文本" },
      ];
      widgets.forEach((type) => {
        this.widgetGroups.push({
          id: guid2(),
          content: "",
          description: "",
          hidden: false,
          icon: "",
          imageUrl: "",
          images: [
            // {
            //   description: "",
            //   hidden: false,
            //   icon: "",
            //   seq: 0,
            //   subTitle: "",
            //   title: "",
            //   url: "",id
            // },
          ],
          seq: 0,
          subTitle: "",
          title: "",
          typeId: type.id,
          videoCoverUrl: "",
          videoUrl: "",
          videos: [
            // {
            //   coverUrl: "",
            //   description: "",
            //   hidden: false,
            //   icon: "",
            //   seq: 0,
            //   subTitle: "",
            //   title: "",
            //   url: "",
            // },
          ],
        });
      });
    },

    getWidgetIcon(val) {
      switch (val) {
        case 1:
          return "el-icon-picture";
        case 2:
          return "el-icon-picture-outline";
        case 3:
          return "el-icon-video-camera";
        case 4:
          return "el-icon-video-camera-solid";
        case 5:
          return "el-icon-s-claim";
        case 6:
          return "el-icon-edit-outline";
        case 7:
          return "el-icon-notebook-2";
        default:
          return "图片";
      }
    },
    getPageDetail(id) {
      this.loading = true;
      GetPagesById(id)
        .then((res) => {
          for (const key in this.pageInfo) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.pageInfo[key] = res.data[key];
            }
          }
          return GetPagesSectionsById(id);
        })
        .then((res) => {
          this.pageViews = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$notify.error({
            title: "拉取错误",
            position: "bottom-right",
            duration: 1500,
            dangerouslyUseHTMLString: true,
            message: `<p>状态码：${err.status} </p>
                    <p>错误类型：${err.error} </p>
                    <p>错误信息：${err.message}</p>`,
          });
        });
    },

    // 给返回来的数据设置独立的id
    setDataUniqueId(sectionsData) {
      let data = [];
      sectionsData.map((item) => {
        data.push({
          id: item.id,
          hidden: item.hidden,
          sequence: item.sequence,
          slides: item.slides,
          type: item.type,
          urlImage: item.urlImage,
          urlVideo: item.urlImage,
          urlVideoCover: item.urlVideoCover,
        });
      });
      return data;
    },

    formatFormData() {
      let data = Array.from(this.pageViews);
      data = data.map((item, index) => {
        delete item.id;
        item.seq = index;
        // slides里面增加uid,提交表单的时候需要移除
        if (item.typeId === "IMAGES") {
          item.images = item.images.map((ele, i) => {
            delete ele.id;
            ele.seq = i;
            return ele;
          });
        }
        if (item.typeId === "VIDEOS") {
          item.videos = item.videos.map((ele, i) => {
            delete ele.id;
            ele.seq = i;
            return ele;
          });
        }
        return item;
      });
      return data;
    },

    validateForm() {
      let isPass = true;
      if (isEmprty(this.pageInfo.name)) {
        this.$message.error("请输入页面名称");
        return false;
      }
      if (isEmprty(this.pageInfo.title)) {
        this.$message.error("请输入页面标题");
        return false;
      }
      if (this.pageViews.length <= 0) {
        this.$message.error("页面版块不能为空");
        return false;
      }
      return true;
    },

    onSubmit() {
      if (!this.validateForm()) {
        return false;
      }
      console.log("提交的表单数据", this.formatFormData());
      this.submiting = true;
      PostPages([
        {
          ...this.pageInfo,
          sections: this.formatFormData(),
        },
      ])
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.submiting = false;
            this.$notify({
              title: "成功",
              message: "新增成功",
              position: "bottom-right",
              type: "success",
            });
            this.initForm();
          }
        })
        .catch((err) => {
          this.submiting = false;
          console.error(err);
          this.$notify.error({
            title: "提交错误",
            position: "bottom-right",
            duration: 1500,
            dangerouslyUseHTMLString: true,
            message: `<p>状态码：${err.status} </p>
                    <p>错误类型：${err.error} </p>
                    <p>错误信息：${err.message}</p>`,
          });
        });
    },

    onEdit() {
      if (!this.validateForm()) {
        return false;
      }
      console.log("提交的表单数据", this.formatFormData());
      this.submiting = true;
      PutPagesById(this.currentId, {
        ...this.pageInfo,
        sections: this.formatFormData(),
      })
        .then((res) => {
          this.submiting = false;
          this.$notify({
            title: "成功",
            message: "修改成功",
            position: "bottom-right",
            type: "success",
          });
          this.initForm();
          this.getPageDetail(this.currentId);
        })
        .catch((err) => {
          this.submiting = false;
          console.error(err);
          this.$notify.error({
            title: "提交错误",
            position: "bottom-right",
            duration: 1500,
            dangerouslyUseHTMLString: true,
            message: `<p>状态码：${err.status} </p>
                    <p>错误类型：${err.error} </p>
                    <p>错误信息：${err.message}</p>`,
          });
        });
    },

    onPageSectionSet(item, index) {
      this.currentIndex = index;
      this.currentTarget = Object.assign({}, item);
      this.activeName = "section";
    },

    addPageSection() {
      this.pageViews.push({
        id: Math.floor(Math.random() * 10000000 + 1000000000),
        content: "",
        description: "",
        hidden: false,
        icon: "",
        imageUrl: "",
        images: [
          {
            description: "",
            hidden: false,
            icon: "",
            seq: 0,
            subTitle: "",
            title: "",
            url: "",
          },
        ],
        seq: this.pageViews.length,
        subTitle: "",
        title: "",
        typeId: "",
        videoCoverUrl: "",
        videoUrl: "",
        videos: [
          {
            coverUrl: "",
            description: "",
            hidden: false,
            icon: "",
            seq: 0,
            subTitle: "",
            title: "",
            url: "",
          },
        ],
      });
    },

    removeFromPage(item, index) {
      this.pageViews.splice(index, 1);
    },

    onSaveSection(target) {
      if (this.currentIndex !== null) {
        this.pageViews[this.currentIndex] = Object.assign({}, target);
        this.currentIndex = null;
        this.currentTarget = {};
      }
    },

    pageDraggable(e) {},
  },
};
</script>

<style lang="scss" scoped>
.tale-main-page {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  position: relative;
}

.base-page-header {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 33;
}
.page-edit-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  ::v-deep {
    .el-image-viewer__close {
      color: #989898;
    }
  }
  .scroller {
    width: 100%;
    height: 100%;
  }
  .page-view-wrapper {
    box-sizing: border-box;
    position: absolute;
    left: 280px;
    right: 450px;
    height: 100%;
    bottom: 0;
    background-color: #504e4e;
    z-index: 1;
    overflow: hidden;
    .page-phone-wrapper {
      box-sizing: border-box;
      width: 360px;
      position: absolute;
      top: 10px;
      left: 50%;
      bottom: 10px;
      border-radius: 10px;
      transform: translateX(-50%);
      box-shadow: 0 0 10px 2px rgb(61, 60, 60);
      background-color: #fff;
      .page-phone-add {
        position: absolute;
        bottom: 0;
        left: 5%;
        right: 5%;
        width: 90%;
        height: 45px;
        margin: 0 auto;
        background-color: rgb(83, 83, 83);
        box-shadow: 0 0 10px 2px rgb(221, 221, 221);
        border-top: 1px solid #f1f1f1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #989898;
        cursor: pointer;
        &:hover {
          background-color: #302f2f;
          color: #888888;
        }
      }
    }
  }
  .page-view-widget-wrap {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 280px;
    height: 100%;
    padding: 10px;
    background-color: rgb(56, 56, 56);
    box-shadow: 0 5px 10px 2px rgb(71, 71, 71);
    bottom: 0;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
    .page-view-widget-drag-list {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .widget-item {
      width: 120px;
      height: 80px;
      border-radius: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-shadow: 0 5px 10px 2px rgb(49, 49, 49);
      background-color: #222222;
      color: #fff;
      cursor: pointer;
      float: left;
      .widget-item-icon {
        font-size: 35px;
        margin-bottom: 5px;
      }
    }
  }
  .page-widget-set-wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 450px;
    height: 100%;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 5px 10px 2px rgb(182, 182, 182);
    bottom: 0;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .page-list-view {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 5px;
    padding-bottom: 60px;
    font-size: 16px;
    position: relative;
    .list-group-item {
      box-sizing: border-box;
      width: 100%;
      height: 110px;
      box-shadow: 0 0 10px 2px #ddd;
      margin-bottom: 8px;
      // padding: 5px 10px;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1em;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      overflow: hidden;
      border-radius: 2px;
      background-color: #fff;
      outline: 1px solid #98c4f0;
      &.disable::before {
        display: none;
      }
      &.active {
        outline: 3px solid #409eff;
      }
      &.disable {
        background-color: #f4f4f5;
      }
      .list-group-item-head {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
      }
      .list-group-item-control {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 30px;
        background-color: rgba($color: #000000, $alpha: 0.6);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 6;
      }
      .list-group-item-title {
        font-size: 13px;
        color: #fff;
        text-align: left;
      }
      .list-group-item-sequence {
        padding: 5px;
        font-size: 12px;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: royalblue;
        border-top-right-radius: 5px;
        cursor: move;
      }
      .list-group-item-icon {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 0;
        left: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #ccc;

        transition: opacity 0.2s linear;
        z-index: 3;
      }
      .list-group-item-edit,
      .list-group-item-delete {
        width: 28px;
        height: 28px;
        display: block;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: transform 0.3s linear;
        position: absolute;
        bottom: 0;
        right: 0;
        // background-color: #f1f1f1;
        border-top-left-radius: 4px;
        opacity: 0;
        transition: all 0.3s linear;
        color: #fff;
        background-color: #333;
        &:hover {
          font-size: 18px;
        }
      }
      .list-group-item-delete {
        color: #f56c6c;
        right: 32px;
      }
      &:hover {
        .list-group-item-edit,
        .list-group-item-delete {
          opacity: 1;
        }
      }
    }
  }
  .page-name {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 5px;
    background-color: #e7e7e7;
  }
  .section-title {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 25px;
    font-size: 14px;
    color: #989898;
    text-align: left;
  }
}
</style>