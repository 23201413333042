<template>
  <video
    :src="url"
    class="video-widget"
    controls
    muted
    :poster="converUrl"
  ></video>
</template>

<script>
export default {
  props: {
    url: "",
    converUrl: "",
  },
};
</script>

<style lang="scss" scoped>
.video-widget {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>