<template>
  <el-image
    class="img-widget"
    :src="url"
    fit="conver"
    :preview-src-list="[url]"
  ></el-image>
</template>

<script>
export default {
  props: {
    url: "",
  },
};
</script>

<style lang="scss" scoped>
.img-widget {
  width: 100%;
  height: 100%;
}
</style>