<template>
  <el-carousel trigger="click" height="100%" class="base-slide">
    <el-carousel-item v-for="item in videoSildes" :key="item.key">
      <video class="silde-item" :src="item.url" controls muted :poster="item.coverUrl"></video>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  props: {
    videos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      videoSildes: [],
    };
  },
  watch: {
    videos: {
      handler() {
        if (this.videos.length <= 0) {
          this.videoSildes = [];
          return false;
        }
        this.videoSildes = [];
        this.videos.forEach((item) => {
          if (!item.hidden && item.url !== "") {
            this.videoSildes.push({
              ...item,
              key: this.guid(),
            });
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.base-slide {
  display: block;
  width: 100%;
  height: 100%;
  .silde-item {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>