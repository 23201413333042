<template>
  <div class="text-wrap">
    <i class="el-icon-s-order base-icon"></i>
    富文本
  </div>
</template>

<script>
export default {
  props: {
    text: "",
  },
};
</script>

<style lang="scss" scoped>
.text-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  .base-icon {
    font-size: 26px;
  }
}
</style>