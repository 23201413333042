import { render, staticRenderFns } from "./FormWidgetSet.vue?vue&type=template&id=f82e0552&scoped=true&"
import script from "./FormWidgetSet.vue?vue&type=script&lang=js&"
export * from "./FormWidgetSet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f82e0552",
  null
  
)

export default component.exports