<template>
  <el-form ref="form" :model="from" label-position="top" label-width="auto">
    <el-form-item label="保存操作">
      <el-button type="primary" size="small" @click="onSave">保存</el-button>
    </el-form-item>
    <el-form-item label="图片">
      <ImgGroupsUpload v-model="from.images" />
    </el-form-item>
    <el-form-item label="标题">
      <el-input v-model="from.title" clearable placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="子标题">
      <el-input
        v-model="from.subTitle"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="描述">
      <el-input
        v-model="from.description"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item label="次序">
      <el-input-number v-model="from.seq"></el-input-number>
    </el-form-item>
    <el-form-item label="页面图标">
      <el-input
        disabled
        v-model="from.icon"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item> -->
    <el-form-item label="显示/隐藏">
      <el-switch
        v-model="from.hidden"
        active-color="#ff4949"
        inactive-color="#13ce66"
        active-text="隐藏"
        inactive-text="显示"
      >
      </el-switch>
    </el-form-item>
  </el-form>
</template>

<script>
import ImgGroupsUpload from "./imgUpload/imgUpload";
export default {
  name: "ImgSildeWidgetSet",
  components: { ImgGroupsUpload },
  props: {
    widgetData: {
      type: Object,
      default() {
        return {
          content: "",
          description: "",
          hidden: false,
          icon: "",
          imageUrl: "",
          images: [],
          redirectUrl:"",
          redirectWxMiniAppPath:"",
          seq: 0,
          subTitle: "",
          title: "",
          typeId: "",
          videoCoverUrl: "",
          videoUrl: "",
          videos: [],
        };
      },
    },
  },
  data() {
    return {
      from: {},
    };
  },
  watch: {
    widgetData: {
      handler: function (newValue, oldValue) {
        this.from = Object.assign({}, newValue);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSave() {
      this.$emit("onSave", this.from);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>